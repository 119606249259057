export const networkHandler = {
  getHost: () => {
    return "https://floating-shelf-92681.herokuapp.com/";
  }
}

// get JSON from a remote address
export const getServerJson = async (endpoint) => {
  try {
    const response = await fetch(networkHandler.getHost() + endpoint);
    const json = await response.json();
    return json;
  }
  catch (e) {
    const json = await e.json();
    return json;
  }
}

// post to a remote address
export const postServerJson = async (endpoint, parameters = []) => {
  try {
    const response = await fetch(networkHandler.getHost() + endpoint, { 
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      body: JSON.stringify(parameters) // <-- Post parameters
     });

    const json = await response.json();
    return json;
  }
  catch (e) {
    const json = await e.json();
    return json;
  }
}