import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';
import styles from '../styles/styles';

import { getServerJson } from '../utils/networkHandler.js';

import HTML from "react-native-render-html";

const {screenWidth, screenHeight} = Dimensions.get('window');

function PageContent (props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [jsonData, setJsonData] = useState({});

  function getJson() {
    getServerJson('api/page_contents/' + props.page_key).then((responseJson) => {
      setIsLoaded(true);
      setJsonData(responseJson);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  if (!isFetched) {
    setIsFetched(true);
    getJson();
  }

  if (isLoaded) {
    return (
      <View style={styles.pageContents}>
        <HTML source={{ html: jsonData.content }} contentWidth={screenWidth} />
      </View>
    )
  } else {
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    )
  }
}

export default PageContent;
