import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';

import styles from '../styles/styles';

const {width, height} = Dimensions.get('window');

function SurveyOption (props) {
  const clickOption = () => {
    props.handleSelectOption(props.option.id);
  }

  if (props.selected == props.option.id) {
    return (
      <View key={props.option.id}>
        <TouchableOpacity onClick={(e) => clickOption() } style={styles.questionOptionButtonSelected} >
          <Text onClick={(e) => clickOption() } style={styles.questionOptionButtonText}>{props.option.label}</Text>
        </TouchableOpacity>
      </View>
    )
  } else {
    return (
      <View key={props.option.id}>
        <TouchableOpacity onClick={(e) => clickOption() } style={styles.questionOptionButton} >
          <Text onClick={(e) => clickOption() } style={styles.questionOptionButtonText}>{props.option.label}</Text>
        </TouchableOpacity>
      </View>
    )
  }
}

export default SurveyOption;