import { Dimensions, StyleSheet } from 'react-native';

const {width, height} = Dimensions.get('window');

const styles = StyleSheet.create({
  safeAreaView: {
    width: '100%',
    height: '100%',
  },
  pageContainer: {
    textAlign:'top',
    width: '100%',
    padding:15,
    marginBottom:50
  },
  landingPageHeader: {
    fontSize:28,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop:150,
    paddingBottom:50
  },
  resultsHeader: {
    fontSize:28,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop:15,
    paddingBottom:50
  },
  smallText: {
    paddingTop:20,
    fontSize:18,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  landingPageButtonText: {
    fontSize:28,
  },
  questionOptionButton: {
    borderWidth: 1,
    borderRadius: 15,
    backgroundColor: '#ffffff',
    marginTop: 10,
    marginBottom: 20,
    marginLeft:12,
    marginRight:12,

  },
  questionOptionButtonSelected: {
    borderWidth: 1,
    borderRadius: 15,
    marginTop: 10,
    marginBottom: 20,
    marginLeft:12,
    marginRight:12,
    backgroundColor: '#adadad'
  },
  questionOptionButtonText: {
    padding: 15
  },
  continueButton: {
    borderWidth: 1,
    borderRadius: 15,
    backgroundColor: '#8896f8',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft:'auto',
    marginRight:'auto',
  },
  continueButtonText: {
    padding: 15
  },
  landingPageButton: {
    borderWidth: 1,
    borderRadius: 15,
    backgroundColor: '#ffffff',
    marginTop: 'auto',
    marginBottom: 'auto',
    marginLeft:'auto',
    marginRight:'auto',
    padding:15,
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10
  },
  questionLabelText: {
    fontSize:28,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 10,
    paddingBottom: 25,
  },
  questionOptionLabelText: {
    fontSize:20,
  },
  pageContents: {
    marginLeft: 'auto',
    marginRight: 'auto',
    justifyContent: 'center',
    alignText:'center',
    paddingLeft:35,
    paddingRight:35,
    paddingBottom:10
  },
  storyBox: {
    backgroundColor: '#d2d2d2',
    borderRadius: 15,
    borderWidth: 0,
    shadowColor: 'rgba(0, 0, 0, 1)',
    shadowOffset: {width: 2, height: 2},
    shadowRadius: 8,
    padding:12,
    marginLeft:30,
    marginRight: 30
  },
  storyBoxHeader: {
    fontWeight: 'bold'
  },
  explanationChart: {
    backgroundColor: '#d2d2d2',
    borderRadius: 15,
    borderWidth: 0,
    shadowColor: 'rgba(0, 0, 0, 1)',
    shadowOffset: {width: 2, height: 2},
    shadowRadius: 8,
    padding:12,
    marginTop:20,
    marginLeft:30,
    marginRight: 30
  },
  explanationText: {
    fontStyle: 'italic',
    paddingLeft:10,
    paddingBottom:12
  }
});
export default styles;
