import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';

import axios from "axios";
import { LineChart } from "react-native-chart-kit";
import { getServerJson, networkHandler } from '../utils/networkHandler.js';


import styles from '../styles/styles';

const {screenWidth, screenHeight} = Dimensions.get('window');

function SurveyResults (props) {

  const [isPosted, setIsPosted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jsonData, setJsonData] = useState({});
  const [incorrectCount, setIncorrectCount] = useState(0);
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
    legend: ["Events"] // optional
  });
  const [wrongIds, setWrongIds] = useState([]);

  const postJson = async () => {
    let data = {
      'responses': props.userResponseData
    };

    try {
      const response = await axios.post(networkHandler.getHost() + 'api/results', data);
      if (response) {
//        alert(JSON.stringify(response));
        setIncorrectCount(response.data.incorrect);
        setWrongIds(response.data.wrong_ids);
        setGraphData(response.data.graph_data);
        setJsonData(response.data);
        setIsLoaded(true);
        let newChartData = {
          labels: [],
          datasets: [],
          legend: ["Events"] // optional
        };

        newChartData['labels'] = response.data.graph_data.labels;

        for (var i = response.data.graph_data.datasets.length - 1; i >= 0; i--) {
          let strokeWidth = 0;
          if (i == 0) {
            strokeWidth = 5;
          }
          let dataset = response.data.graph_data.datasets[i];
          newChartData['datasets'].push({
            data: datasets.data,
            color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            strokeWidth: strokeWidth // optional
          });
        }

        setGraphData(newChartData);
      }
    //      setSubmitEnabled(true);
    } catch (error) {
//      alert(JSON.stringify(error));
    //      setSubmitEnabled(true);
    }
  }

  const renderChart = () => {
    return(
      <View>
        <Text style={{padding:10}}><Text style={{fontWeight:'bold'}}>* NOTE: </Text> 'You' refers to your consciousness, the consciousness of your anscestors and the line of causality which led to where you are now.</Text>
        <Text style={{padding:10}}>Here is a chart representing your potential course across the multiverse.</Text>
        <LineChart
          data={graphData}
          width={Dimensions.get("window").width - 30} // from react-native
          height={220}
          yAxisLabel=""
          yAxisSuffix=" U"
          segments={4}
          yAxisInterval={1} // optional, defaults to 1
          chartConfig={{
            backgroundColor: "#e26a00",
            backgroundGradientFrom: "#430ca6",
            backgroundGradientTo: "#8896f8",
            decimalPlaces: 0, // optional, defaults to 2dp
            color: (opacity = 1) => `rgba(0, 255, 255, ${opacity})`,
            labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
            strokeWidth: 15,
            style: {
              borderRadius: 16
            },
            propsForDots: {
              r: "6",
              strokeWidth: "2",
              stroke: "#812ae8"
            }
          }}
          bezier
          style={{
            marginVertical: 8,
            borderRadius: 16
          }}
        />
      </View>
    )
  }

  const renderStory = () => {
    return (
      <View style={styles.storyBox}>
        <Text style={styles.storyBoxHeader}>Here is your journey from what we can tell.</Text>
        {
          jsonData.story_sequences.map((story_line) => {
            return (
              <Text style={styles.storyBoxText}>{story_line}</Text>
            )
          })
        }
      <Text>You have been in this universe for a maximum of <Text style={{fontWeight:'bold'}}>{jsonData.days_in_universe}</Text> days.</Text>
      </View>
    )
  }

  if (!isPosted) {
    postJson();
    setIsPosted(true);
  }

  if (isLoaded) {
    if (incorrectCount > 0) {
      return (
        <SafeAreaView style={[ styles.safeAreaView ]}>
          <ScrollView contentContainerStyle={[styles.pageContainer]}>
            <Text style={styles.resultsHeader}>You are not from this universe</Text>
            {renderChart()}
            {renderStory()}
            <Text style={{paddingTop:10, paddingBottom:10}}>Based on all the available science we have calculated the statistical sequence of events that led to this.</Text>
            <TouchableOpacity onClick={(e) => props.revealExplanation() }  style={styles.landingPageButton} >
              <Text onClick={(e) => props.revealExplanation() } style={[ styles.landingPageButtonText ]}>Dive Into The Science</Text>
            </TouchableOpacity>
          </ScrollView>
        </SafeAreaView>
      )
    } else {
      return (
        <SafeAreaView  style={[ styles.safeAreaView ]}>
          <ScrollView contentContainerStyle={[styles.pageContainer]}>
          <Text style={styles.resultsHeader}>You are from universe 1A (This Universe)</Text>
          {renderChart()}
          {renderStory()}
          <Text style={{paddingTop:10, paddingBottom:10}}>Based on all the available science we have calculated the statistical sequence of events that led to this.</Text>
          <TouchableOpacity onClick={(e) => props.revealExplanation() }  style={styles.landingPageButton} >
            <Text onClick={(e) => props.revealExplanation() } style={[ styles.landingPageButtonText ]}>Dive Into The Science</Text>
          </TouchableOpacity>
          </ScrollView>
        </SafeAreaView>
      )
    }
  } else {
    return (null);
  }
}

export default SurveyResults;