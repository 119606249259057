import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

/*
*  dependencys should be avoided and only used if reliably stable.
*  each dependancy must be sourced 
*/
// source: https://www.npmjs.com/package/@react-navigation/native
// docs: https://reactnavigation.org/
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

// self contained references that other parts of the code will use
import { navigationRef } from './src/utils/navigationHandler.js';
import { getServerJson } from './src/utils/networkHandler.js';

// Pages of the app
import Home from './src/components/Home';
import Survey from './src/components/Survey';

export default function App() {
  const navigatorRef = React.useRef();

  const linking = {
    config: {
      screens: {
        Home: {
          path: '/'
        },
        Survey: {
          path: '/survey',
        }
      }
    },
  };

  const Stack = createStackNavigator();

  return (
    <NavigationContainer 
      linking={linking}
      ref={navigationRef}
      >
      <Stack.Navigator>
        <Stack.Screen name="Home" component={ props => <Home {...props} /> }  />
        <Stack.Screen name="Survey" component={Survey} />
      </Stack.Navigator>
      <Text>We do not save any data.  This is effectively a useless toy.</Text>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});