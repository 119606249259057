import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';

import axios from "axios";
import { LineChart } from "react-native-chart-kit";
import { getServerJson, networkHandler } from '../utils/networkHandler.js';

import PageContent from './PageContent';

import styles from '../styles/styles';

const {screenWidth, screenHeight} = Dimensions.get('window');

function SurveyExplained (props) {
  const [questionsExplainedData, setQuestionsExplainedData] = useState({});
  const [isPosted, setIsPosted] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [wrongIds, setWrongIds] = useState([]);

  const postJson = async () => {
    let data = {
      'responses': props.userResponseData
    };

    try {
      const response = await axios.post(networkHandler.getHost() + 'api/results', data);
      if (response) {
        setQuestionsExplainedData(response.data.questions_explained);
        setWrongIds(response.data.wrong_ids);
        setIsLoaded(true);
      }
    //      setSubmitEnabled(true);
    } catch (error) {
//      alert(JSON.stringify(error));
    //      setSubmitEnabled(true);
    }
  }

  if (!isPosted) {
    postJson();
    setIsPosted(true);
  }

  if (isLoaded && isPosted) {
    return (
    <SafeAreaView  style={[ styles.safeAreaView ]}>
      <ScrollView contentContainerStyle={[styles.pageContainer]}>
        <PageContent style={styles.pageContents} key="explanation_header" page_key="explanation_header" />

        <PageContent style={styles.pageContents} key="we_lied" page_key="we_lied" />

        <PageContent style={styles.pageContents} key="let_down" page_key="let_down" />

        <PageContent style={styles.pageContents} key="initial_bias" page_key="initial_bias" />

        <PageContent style={styles.pageContents} key="magic_brain" page_key="magic_brain" />

        <View style={styles.explanationChart}>
        <Text style={{fontWeight: 'bold', fontSize:28, margin:12}}>Explanation Breakdown:</Text>
        {
          questionsExplainedData.map((question_information) => {
            if (question_information.their_option.id != question_information.true_option.id) {
              return (
                <View>
                  <Text>{question_information.question.label} <Text style={{fontWeight:'bold'}}>You Thought:</Text> {question_information.their_option.label}  <Text style={{fontWeight:'bold'}}>Reality:</Text> {question_information.true_option.label} </Text>
                  <Text style={styles.explanationText}>Explanation: {question_information.their_option.explanation}</Text>
                </View>
              )
            }
          })
        }
        </View>
        </ScrollView>
      </SafeAreaView>

    )
  } else {
    return (null);
  }
}

export default SurveyExplained;
