import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';

import { getServerJson } from '../utils/networkHandler.js';
import { navigationRef } from '../utils/navigationHandler.js';

import SurveyQuestion from './SurveyQuestion.js';
import SurveyResults from './SurveyResults.js';
import SurveyExplained from './SurveyExplained.js';

import styles from '../styles/styles';

const {width, height} = Dimensions.get('window');

function Survey (props) {
  const [isFetched, setIsFetched] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jsonData, setJsonData] = useState({});

  const [userResponseData, setUserResponseData] = useState({});
  const [onQuestion, setOnQuestion] = useState(1);

  const [showExplanation, setShowExplanation] = useState(false);





//  let userDataOverride = [{"id":1,"selected":1},{"id":6,"selected":12},{"id":3,"selected":6},{"id":5,"selected":10},{"id":8,"selected":17},{"id":2,"selected":3},{"id":7,"selected":14},{"id":4,"selected":7}];





  if (!isFetched) {
    getJson();
    setIsFetched(true);
  }

  function getJson() {
    getServerJson('api/questions').then((responseJson) => {
      setJsonData(responseJson);

      let userResponses = [];
      for (var i = responseJson.questions.length - 1; i >= 0; i--) {
        let question_response_data = {
          'id': responseJson.questions[i].id,
          'selected': 0
        }
        userResponses.push(question_response_data);
      }

//      console.log("Set to nothing");
      setUserResponseData(userResponses);




 //     setUserResponseData(userDataOverride);
   //   setOnQuestion(responseJson.questions.length + 1);




      setIsLoaded(true);
    })
    .catch((error) => {
      console.error(error);
    });
  }

  function selectOption(question_id, option_id) {
    let newResponses = [];
    for (var i = userResponseData.length - 1; i >= 0; i--) {
      if (userResponseData[i].id == question_id) {
        let updated_question_response = {
          'id': question_id,
          'selected': option_id
        }
        newResponses.push(updated_question_response);
      } else {
        newResponses.push(userResponseData[i]);
      }
    }
    setUserResponseData(newResponses);
  }

  function clickContinue() {
    setOnQuestion(onQuestion + 1);
  }

  function revealExplanation() {
    setShowExplanation(true);
  }


  let question_number = 0;

  if (!isLoaded || !isFetched) {
    return (null)
  } else {
    if (onQuestion > jsonData.questions.length) {
      if (showExplanation) {
        return (
          <SurveyExplained userResponseData={userResponseData} />
        )
      } else {
        return (
          <SurveyResults revealExplanation={revealExplanation} userResponseData={userResponseData} />
        )
      }
    } else {
      return (
        <View  style={[ styles.safeAreaView ]}>
          <ScrollView contentContainerStyle={[styles.pageContainer]}>
            <View>
              {
                jsonData.questions.map((question) => {
                  question_number++;
                  return (
                    <SurveyQuestion handleClickContinue={clickContinue} handleSelectOption={selectOption} key={question.id} userResponseData={userResponseData} onQuestion={onQuestion} question_number={question_number} question={question} />
                  )
                })
              }
            </View>
          </ScrollView>
        </View>
      )
    }
  }
}

export default Survey;