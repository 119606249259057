import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';

import SurveyOption from './SurveyOption.js';

import styles from '../styles/styles';

const {width, height} = Dimensions.get('window');

function SurveyQuestion (props) {
  function selectOption(option_id) {
    props.handleSelectOption(props.question.id, option_id);
  }

  function clickContinue() {
    props.handleClickContinue();
  }

  let selected = 0;
  for (var i = props.userResponseData.length - 1; i >= 0; i--) {
    if (props.userResponseData[i].id == props.question.id) {
      selected = props.userResponseData[i].selected;
    }
  }

  const continueButton = () => {
    if (selected > 0) {
      return (
        <TouchableOpacity onClick={(e) => clickOption() } style={styles.questionOptionButton} >
          <Text style={styles.continueButtonText} onClick={(e) => clickContinue() }>Continue</Text>
        </TouchableOpacity>
      )
    }
  }

  if (props.onQuestion == props.question_number) {
    return (
      <View key={props.question.id}>
        <Text style={styles.questionLabelText}>{props.question.label}</Text>
        <View style={{flex:1, flexDirection:'row', marginLeft:'auto', marginRight:'auto'}} >
        {
          props.question.options.map((option) => {
            return (
              <SurveyOption key={option.id} selected={selected} handleSelectOption={selectOption} option={option} />
            )
          })
        }
        </View>
        {continueButton()}
      </View>
    )
  } else {
    return (null);
  }
}

export default SurveyQuestion;