import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, ScrollView, SafeAreaView, Image, Dimensions, TouchableOpacity } from 'react-native';

import { fetchUpdatedCart } from '../utils/networkHandler.js';
import { navigationRef } from '../utils/navigationHandler.js';

import styles from '../styles/styles';

const {width, height} = Dimensions.get('window');

function Home (props) {

  function goToSurvey() {
    navigationRef.current.navigate('Survey', null);
  }

  return (
    <View  style={[ styles.safeAreaView ]}>
      <ScrollView contentContainerStyle={[styles.pageContainer]}>
        <View>
          <Text style={styles.landingPageHeader}>What Universe Are You From?</Text>
          <TouchableOpacity onClick={(e) => goToSurvey() }  style={styles.landingPageButton} >
            <Text onClick={(e) => goToSurvey() } style={[ styles.landingPageButtonText ]}>Find out</Text>
          </TouchableOpacity>
          <Text style={styles.smallText}>(Honesty = Accuracy)</Text>
          <Text style={styles.smallText}>Looking up answers will only give you results for this universe.</Text>
        </View>
      </ScrollView>
    </View>
  )
}

export default Home;